.tailwind *, .tailwind :before, .tailwind :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

.tailwind :before, .tailwind :after {
  --tw-content: "";
}

.tailwind html, .tailwind :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

.tailwind body {
  line-height: inherit;
  margin: 0;
}

.tailwind hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

.tailwind abbr:where([title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
}

.tailwind h1, .tailwind h2, .tailwind h3, .tailwind h4, .tailwind h5, .tailwind h6 {
  font-size: inherit;
  font-weight: inherit;
}

.tailwind a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.tailwind b, .tailwind strong {
  font-weight: bolder;
}

.tailwind code, .tailwind kbd, .tailwind samp, .tailwind pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

.tailwind small {
  font-size: 80%;
}

.tailwind sub, .tailwind sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.tailwind sub {
  bottom: -.25em;
}

.tailwind sup {
  top: -.5em;
}

.tailwind table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.tailwind button, .tailwind input, .tailwind optgroup, .tailwind select, .tailwind textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.tailwind button, .tailwind select {
  text-transform: none;
}

.tailwind button, .tailwind input:where([type="button"]), .tailwind input:where([type="reset"]), .tailwind input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

.tailwind :-moz-focusring {
  outline: auto;
}

.tailwind :-moz-ui-invalid {
  box-shadow: none;
}

.tailwind progress {
  vertical-align: baseline;
}

.tailwind ::-webkit-inner-spin-button {
  height: auto;
}

.tailwind ::-webkit-outer-spin-button {
  height: auto;
}

.tailwind [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.tailwind ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.tailwind ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.tailwind summary {
  display: list-item;
}

.tailwind blockquote, .tailwind dl, .tailwind dd, .tailwind h1, .tailwind h2, .tailwind h3, .tailwind h4, .tailwind h5, .tailwind h6, .tailwind hr, .tailwind figure, .tailwind p, .tailwind pre {
  margin: 0;
}

.tailwind fieldset {
  margin: 0;
  padding: 0;
}

.tailwind legend {
  padding: 0;
}

.tailwind ol, .tailwind ul, .tailwind menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tailwind dialog {
  padding: 0;
}

.tailwind textarea {
  resize: vertical;
}

.tailwind input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

.tailwind textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

.tailwind input::placeholder, .tailwind textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.tailwind button, .tailwind [role="button"] {
  cursor: pointer;
}

.tailwind :disabled {
  cursor: default;
}

.tailwind img, .tailwind svg, .tailwind video, .tailwind canvas, .tailwind audio, .tailwind iframe, .tailwind embed, .tailwind object {
  vertical-align: middle;
  display: block;
}

.tailwind img, .tailwind video {
  max-width: 100%;
  height: auto;
}

.tailwind [hidden] {
  display: none;
}

.tailwind *, .tailwind :before, .tailwind :after, .tailwind ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.tailwind .container {
  width: 100%;
}

@media (width >= 640px) {
  .tailwind .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .tailwind .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .tailwind .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .tailwind .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .tailwind .container {
    max-width: 1536px;
  }
}

.tailwind .fixed {
  position: fixed;
}

.tailwind .absolute {
  position: absolute;
}

.tailwind .relative {
  position: relative;
}

.tailwind .inset-x-0 {
  left: 0;
  right: 0;
}

.tailwind .-left-1 {
  left: -.25rem;
}

.tailwind .-left-1\.5 {
  left: -.375rem;
}

.tailwind .-right-2 {
  right: -.5rem;
}

.tailwind .bottom-0 {
  bottom: 0;
}

.tailwind .bottom-10 {
  bottom: 2.5rem;
}

.tailwind .bottom-4 {
  bottom: 1rem;
}

.tailwind .left-1 {
  left: .25rem;
}

.tailwind .right-0 {
  right: 0;
}

.tailwind .right-10 {
  right: 2.5rem;
}

.tailwind .right-4 {
  right: 1rem;
}

.tailwind .top-0 {
  top: 0;
}

.tailwind .top-1 {
  top: .25rem;
}

.tailwind .top-1\/2 {
  top: 50%;
}

.tailwind .top-\[200\.0px\] {
  top: 200px;
}

.tailwind .z-50 {
  z-index: 50;
}

.tailwind .m-2 {
  margin: .5rem;
}

.tailwind .m-4 {
  margin: 1rem;
}

.tailwind .-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.tailwind .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tailwind .my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tailwind .mb-1 {
  margin-bottom: .25rem;
}

.tailwind .mb-10 {
  margin-bottom: 2.5rem;
}

.tailwind .mb-2 {
  margin-bottom: .5rem;
}

.tailwind .mb-4 {
  margin-bottom: 1rem;
}

.tailwind .mb-8 {
  margin-bottom: 2rem;
}

.tailwind .ml-2 {
  margin-left: .5rem;
}

.tailwind .ml-4 {
  margin-left: 1rem;
}

.tailwind .ml-8 {
  margin-left: 2rem;
}

.tailwind .mr-2 {
  margin-right: .5rem;
}

.tailwind .mr-4 {
  margin-right: 1rem;
}

.tailwind .mt-1 {
  margin-top: .25rem;
}

.tailwind .mt-1\.5 {
  margin-top: .375rem;
}

.tailwind .mt-2 {
  margin-top: .5rem;
}

.tailwind .mt-4 {
  margin-top: 1rem;
}

.tailwind .block {
  display: block;
}

.tailwind .inline-block {
  display: inline-block;
}

.tailwind .inline {
  display: inline;
}

.tailwind .flex {
  display: flex;
}

.tailwind .grid {
  display: grid;
}

.tailwind .hidden {
  display: none;
}

.tailwind .h-10 {
  height: 2.5rem;
}

.tailwind .h-12 {
  height: 3rem;
}

.tailwind .h-16 {
  height: 4rem;
}

.tailwind .h-3 {
  height: .75rem;
}

.tailwind .h-3\.5 {
  height: .875rem;
}

.tailwind .h-4 {
  height: 1rem;
}

.tailwind .h-48 {
  height: 12rem;
}

.tailwind .h-6 {
  height: 1.5rem;
}

.tailwind .h-7 {
  height: 1.75rem;
}

.tailwind .h-8 {
  height: 2rem;
}

.tailwind .h-96 {
  height: 24rem;
}

.tailwind .h-auto {
  height: auto;
}

.tailwind .h-full {
  height: 100%;
}

.tailwind .w-1\/2 {
  width: 50%;
}

.tailwind .w-10 {
  width: 2.5rem;
}

.tailwind .w-12 {
  width: 3rem;
}

.tailwind .w-16 {
  width: 4rem;
}

.tailwind .w-3 {
  width: .75rem;
}

.tailwind .w-4 {
  width: 1rem;
}

.tailwind .w-48 {
  width: 12rem;
}

.tailwind .w-6 {
  width: 1.5rem;
}

.tailwind .w-7 {
  width: 1.75rem;
}

.tailwind .w-8 {
  width: 2rem;
}

.tailwind .w-80 {
  width: 20rem;
}

.tailwind .w-full {
  width: 100%;
}

.tailwind .max-w-4xl {
  max-width: 56rem;
}

.tailwind .max-w-lg {
  max-width: 32rem;
}

.tailwind .max-w-max {
  max-width: max-content;
}

.tailwind .max-w-md {
  max-width: 28rem;
}

.tailwind .max-w-screen-xl {
  max-width: 1280px;
}

.tailwind .max-w-sm {
  max-width: 24rem;
}

.tailwind .flex-1 {
  flex: 1;
}

.tailwind .flex-none {
  flex: none;
}

.tailwind .flex-shrink-0 {
  flex-shrink: 0;
}

.tailwind .flex-grow {
  flex-grow: 1;
}

.tailwind .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tailwind .translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(1.5rem, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tailwind .rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tailwind .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tailwind .cursor-pointer {
  cursor: pointer;
}

.tailwind .resize {
  resize: both;
}

.tailwind .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tailwind .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tailwind .flex-col {
  flex-direction: column;
}

.tailwind .flex-wrap {
  flex-wrap: wrap;
}

.tailwind .items-end {
  align-items: flex-end;
}

.tailwind .items-center {
  align-items: center;
}

.tailwind .items-baseline {
  align-items: baseline;
}

.tailwind .justify-center {
  justify-content: center;
}

.tailwind .justify-between {
  justify-content: space-between;
}

.tailwind .gap-2 {
  gap: .5rem;
}

.tailwind .gap-4 {
  gap: 1rem;
}

.tailwind .space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: .25rem;
  margin-left: calc(.25rem * (1 - var(--tw-space-x-reverse)));
  margin-left: .25rem;
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tailwind .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: .5rem;
  margin-left: calc(.5rem * (1 - var(--tw-space-x-reverse)));
  margin-left: .5rem;
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tailwind .space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: .75rem;
  margin-left: calc(.75rem * (1 - var(--tw-space-x-reverse)));
  margin-left: .75rem;
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tailwind .space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 2.5rem;
  margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
  margin-top: 2.5rem;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: 0;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.tailwind .space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: .5rem;
  margin-top: calc(.5rem * (1 - var(--tw-space-y-reverse)));
  margin-top: .5rem;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: 0;
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.tailwind .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 1rem;
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
  margin-top: 1rem;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: 0;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tailwind .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 2rem;
  margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
  margin-top: 2rem;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: 0;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tailwind .overflow-auto {
  overflow: auto;
}

.tailwind .overflow-hidden {
  overflow: hidden;
}

.tailwind .rounded {
  border-radius: .25rem;
}

.tailwind .rounded-3xl {
  border-radius: 1.5rem;
}

.tailwind .rounded-full {
  border-radius: 9999px;
}

.tailwind .rounded-lg {
  border-radius: .5rem;
}

.tailwind .rounded-md {
  border-radius: .375rem;
}

.tailwind .rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.tailwind .rounded-bl-\[25px\] {
  border-bottom-left-radius: 25px;
}

.tailwind .rounded-br-\[25px\] {
  border-bottom-right-radius: 25px;
}

.tailwind .rounded-tl-\[25px\] {
  border-top-left-radius: 25px;
}

.tailwind .rounded-tr-\[25px\] {
  border-top-right-radius: 25px;
}

.tailwind .border {
  border-width: 1px;
}

.tailwind .border-l-2 {
  border-left-width: 2px;
}

.tailwind .border-t {
  border-top-width: 1px;
}

.tailwind .border-t-2 {
  border-top-width: 2px;
}

.tailwind .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: #f3f4f6;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.tailwind .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.tailwind .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: #d1d5db;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.tailwind .border-gray-400 {
  --tw-border-opacity: 1;
  border-color: #9ca3af;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.tailwind .border-white {
  --tw-border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.tailwind .bg-black {
  --tw-bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.tailwind .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: #dbeafe;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.tailwind .bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: #3b82f6;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.tailwind .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: #2563eb;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.tailwind .bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: #1d4ed8;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.tailwind .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: #f3f4f6;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.tailwind .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.tailwind .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.tailwind .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.tailwind .bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.tailwind .bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.tailwind .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: #111827;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.tailwind .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: #dcfce7;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}

.tailwind .bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: #22c55e;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}

.tailwind .bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: #15803d;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}

.tailwind .bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: #f97316;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.tailwind .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: #fee2e2;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.tailwind .bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: #ef4444;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.tailwind .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: #dc2626;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.tailwind .bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: #b91c1c;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.tailwind .bg-white {
  --tw-bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tailwind .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.tailwind .p-2 {
  padding: .5rem;
}

.tailwind .p-4 {
  padding: 1rem;
}

.tailwind .p-5 {
  padding: 1.25rem;
}

.tailwind .p-6 {
  padding: 1.5rem;
}

.tailwind .p-8 {
  padding: 2rem;
}

.tailwind .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tailwind .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tailwind .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tailwind .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tailwind .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tailwind .py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tailwind .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tailwind .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.tailwind .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tailwind .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tailwind .pb-4 {
  padding-bottom: 1rem;
}

.tailwind .pt-2 {
  padding-top: .5rem;
}

.tailwind .pt-4 {
  padding-top: 1rem;
}

.tailwind .pt-6 {
  padding-top: 1.5rem;
}

.tailwind .text-left {
  text-align: left;
}

.tailwind .text-center {
  text-align: center;
}

.tailwind .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tailwind .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.tailwind .text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.tailwind .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tailwind .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tailwind .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tailwind .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tailwind .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.tailwind .font-bold {
  font-weight: 700;
}

.tailwind .font-extrabold {
  font-weight: 800;
}

.tailwind .font-light {
  font-weight: 300;
}

.tailwind .font-medium {
  font-weight: 500;
}

.tailwind .font-normal {
  font-weight: 400;
}

.tailwind .font-semibold {
  font-weight: 600;
}

.tailwind .uppercase {
  text-transform: uppercase;
}

.tailwind .leading-6 {
  line-height: 1.5rem;
}

.tailwind .leading-none {
  line-height: 1;
}

.tailwind .text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.tailwind .text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.tailwind .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.tailwind .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.tailwind .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.tailwind .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.tailwind .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.tailwind .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.tailwind .text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}

.tailwind .text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.tailwind .text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tailwind .text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}

.tailwind .text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}

.tailwind .underline {
  text-decoration-line: underline;
}

.tailwind .no-underline {
  text-decoration-line: none;
}

.tailwind .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px #0000001a, 0 1px 2px -1px #0000001a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tailwind .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tailwind .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tailwind .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tailwind .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tailwind .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tailwind .transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tailwind .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tailwind .duration-300 {
  transition-duration: .3s;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: #2563eb;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: #1d4ed8;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: #f3f4f6;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: #d1d5db;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: #1f2937;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: #c2410c;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: #b91c1c;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group[open] .group-open\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 640px) {
  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:top-\[250\.5px\] {
    top: 250.5px;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[190px\] {
    height: 190px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:top-\[350\.5px\] {
    top: 350.5px;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:h-\[198px\] {
    height: 198px;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-top: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: 0;
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
}
/*# sourceMappingURL=styles.css.map */
